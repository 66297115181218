import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import StaysTab from '../Home/StaysTab/StaysTab';
import { isAuthGuard } from '../../guards/isAuthGuard';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import UsersService from '../../services/usersService';
import StorageService from '../../services/storage.service';
import { dayInMilliSeconds } from '../../constans/datepicker';
import { STORAGE_DESTINATION } from '../DestinationTree';
import { getShortDestinationName } from '../../helpers/helper-methods';
import SearchInput from '../Search';
import SearchFilters from '../SearchFilters/SearchFilters';
import SimpleSearchResultCard from '../SimpleSearchResultCard';
import { free } from '../../constans/activity-review';
import Pagination from '../Pagination';
import useDebounce from '../../hooks/useDebounce';
import {
  PROPERTY_CATEGORY_FILTER_INITIAL_VALUES,
  PROPERTY_CATEGORY_OPTIONS,
} from '../../constans/property';
import { cardClick, getIds, getLocationName } from '../../helpers/generateLinks';
import { getter, paginationLimit } from '../../helpers/getter';
import { VideoEmulatorLaptop, VideoEmulatorMobile } from '../VideoImulator/VideoEmulator';

const storageService = StorageService();
const getUser = () => storageService.get(LOCALSTORAGE_KEYS.USER, {});
const setTab = (tabId) => storageService.set(LOCALSTORAGE_KEYS.HOME_TAB, tabId);

const laptopVideoLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_6497db2f-e039-4e16-b75c-b526f7167d18.mp4';
const mobileVideoLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_bb2de3ac-bcd2-48b5-a3af-f1e3ff0a2ea6.mp4';

function Home({ destination }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const debouncedQuery = useDebounce(searchQuery, 300);

  const ids = getIds(destination);
  const getData = async ({ selected = 0 }) => {
    const { data, pagination } = await getter({
      filters,
      query: debouncedQuery,
      offset: paginationLimit * selected,
      limit: paginationLimit,
      ids,
    });

    setPage(selected);
    setTotalCount(pagination.totalCount);
    setResults(data);
  };

  useEffect(() => {
    getData({ filters, query: debouncedQuery });
  }, [filters, debouncedQuery]);

  const handleProfileChanges = async (values) => {
    const isAuth = isAuthGuard();

    if (!isAuth) {
      storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser(), searchProfile: values });
      return;
    }

    const { data } = await UsersService().updateSearchProfile(values);

    const {
      // eslint-disable-next-line no-unused-vars
      id, createdAt, updatedAt, ...searchProfile
    } = data;

    storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser(), searchProfile });
  };
  // destination
  const locName = getShortDestinationName(
    destination,
    destination.region,
    destination.region?.city,
    destination.region?.city?.district,
  );

  const initValue = {
    name: destination?.name || '',
    locationName: locName || '',
    countryId: destination?.id || 0,
    regionId: destination?.region?.id || 0,
    cityId: destination?.region?.city?.id || 0,
    districtId: destination?.region?.city?.district?.id || 0,
    adults: 2,
    adultGuests: 2,
    kidGuests: 0,
    rooms: 1,
    checkin: new Date(Date.now() + 7 * dayInMilliSeconds),
    checkout: new Date(Date.now() + 9 * dayInMilliSeconds),
  };

  const currentDestination = {
    country: destination,
    region: destination?.region,
    city: destination?.region?.city,
    district: destination?.region?.city?.district,
  };
  storageService.set(STORAGE_DESTINATION, currentDestination);

  return (
    <div className={`${styles.home} flex-grow-1`}>
      <div className={`${styles.container} flex flex-center`}>
        <div className="col-12">

          <div className={styles.homeContent}>
            <StaysTab
              handleSubmitForm={handleProfileChanges}
              setTab={() => setTab(2)}
              initValues={initValue}
              isMarketingPage
            />

            <div>
              <div className={styles.block}>
                <div className={styles.description}>
                  <h1 className={styles.descriptionCentered}>
                    Your trustworthy travel planning app
                  </h1>
                  <p className={styles.textDescription}>
                    We have researched and scored a
                    comprehensive list of properties in
                    this destination.
                  </p>
                  <p className={styles.textDescription}>
                    Just enter your search details to rank
                    the best property offers based on
                    your personal preferences & budget.
                  </p>
                </div>
                <VideoEmulatorLaptop
                  imgWidth={814}
                  imgHeight={420}
                  videoWidth={650}
                  videoHeight={420}
                  link={laptopVideoLink}
                />
              </div>
              <VideoEmulatorMobile link={mobileVideoLink} />
            </div>

            <div>
              <div className={`flex flex-between w-100 ${styles.searchWrapper}`}>
                <h2 className={`${styles.mainText}`}>
                  {totalCount}
                  {' '}
                  Properties in
                  {' '}
                  {getLocationName(destination)}
                </h2>

                <div className={styles.searchBlock}>
                  <SearchInput
                    containerStyles={styles.search}
                    isSimpleSearch
                    placeholder="Search"
                    onChange={(event) => setSearchQuery(event.currentTarget.value)}
                    value={searchQuery}
                    type="text"
                  />
                  <div
                    className={`flex flex-v-center ${styles.filterBlock}`}
                  >
                    <span className={`${styles.mainText} ${styles.filter}`}>
                      Filter
                    </span>
                    <SearchFilters
                      resetButtonText="No filter"
                      onSubmit={(values) => setFilters(values)}
                      initialValues={PROPERTY_CATEGORY_FILTER_INITIAL_VALUES}
                      filterOptions={[
                        {
                          name: 'values',
                          options: PROPERTY_CATEGORY_OPTIONS,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.results}>
                {results.map(({
                  id,
                  title,
                  subtitle,
                  address,
                  mainPhotoLink,
                  payingOrFree,
                  city,
                  region,
                  country,
                  urlDestination,
                  urlName,
                  link,
                }) => (
                  <div className={styles.resultsItem} key={id}>
                    <SimpleSearchResultCard
                      onClick={(event) => cardClick(
                        title,
                        city,
                        region,
                        country,
                        urlDestination,
                        urlName,
                        event,
                      )}
                      isFree={payingOrFree === free}
                      subtitle={subtitle}
                      title={title}
                      address={address}
                      mainPhotoLink={mainPhotoLink}
                      href={link}
                      city={city?.name || region?.name || country?.name}
                      isMarketing
                      hideChip
                    />
                  </div>
                ))}
              </div>
              <div className={`${styles.pagination} flex flex-center`}>
                <Pagination
                  callback={(v) => getData({
                    filters,
                    query: debouncedQuery,
                    ...v,
                  })}
                  total={totalCount}
                  perPage={paginationLimit}
                  forcePage={Number(page)}
                  className={styles.paginationButton}
                  isMarketing
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
