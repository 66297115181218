import React, { useEffect } from 'react';
import { getQueryParams } from '../../helpers/helper-methods';
import CookiesService from '../../services/cookie.service';
import { COOKIES_KEYS, COOKIES_LIFETIME, SESSIONSTORAGE_KEY } from '../../constans/localstorage';
import http from '../../services/http.service';
import { API } from '../../constans/http';
import SessionService from '../../services/session.service';
import RoleBasedRoute from '../../components/RoleBasedRoute';
import Layout from '../../components/Layout/Layout';
import Home from '../../components/Marketing';
import { nonSellerRoles } from '../../constans/roles';
import { SeoData } from '../../components/StructuredData/StructuredData';

function Index({ pageContext }) {
  const {
    destination, urlPath, propertyData,
  } = pageContext;
  const search = '';
  const { BP } = getQueryParams(search);
  const partnerNickname = CookiesService().getCookie(COOKIES_KEYS.PARTNER_NICKNAME);

  useEffect(() => {
    if (BP) {
      CookiesService().setCookie(
        COOKIES_KEYS.PARTNER_NICKNAME,
        BP,
        COOKIES_LIFETIME,
      );

      http.get(`${API.PARTNERS}/${BP}/logo`, { useToast: false })
        .then(({ data }) => {
          const { file } = data;
          if (file.link) {
            SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
            SessionService().set(SESSIONSTORAGE_KEY.LOGO_SUBTITLE, data.subTitle);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (partnerNickname && !BP) {
      http.get(`${API.PARTNERS}/${partnerNickname}/logo`, { useToast: false })
        .then(({ data }) => {
          const { file } = data;
          if (file.link) {
            SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
            SessionService().set(SESSIONSTORAGE_KEY.LOGO_SUBTITLE, data.subTitle);
          }
        });
    }
  }, []);

  return (
    <RoleBasedRoute
      component={() => (
        <div className="column-flexed-wrapper">
          <SeoData urlPath={urlPath} />
          <Layout>
            <Home destination={destination} />
          </Layout>
        </div>
      )}
      availableRoles={nonSellerRoles}
    />
  );
}

export default Index;
